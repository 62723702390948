import PropTypes from 'prop-types'

import AccountManagerDashboardRow from './AccountManagerDashboardRow'
import AccountSettingsRow from './AccountSettingsRow'
import FooterRow from './FooterRow'
import HelpRow from './HelpRow'
import ProDashboardRow from './ProDashboardRow'
import QuarterbackDashboardRow from './QuarterbackDashboardRow'
import SignOutRow from './SignOutRow'
import UserInfoRow from './UserInfoRow'

import './userDropdown.scss'


const UserDropdown = props => (
  <ul aria-hidden={props.ariaHidden} className='user-dropdown' onClick={props.toggleDropdown}>
    <UserInfoRow currentUser={props.currentUser} />
    <AccountSettingsRow />
    <HelpRow currentUser={props.currentUser} />
    <SignOutRow />
    {
      props.currentUser.firm_user &&
      !props.currentUser.account_manager &&
      <ProDashboardRow currentUser={props.currentUser} />
    }
    {
      props.currentUser.quarterback &&
      <QuarterbackDashboardRow currentUser={props.currentUser} />
    }
    <AccountManagerDashboardRow currentUser={props.currentUser} />
    <FooterRow />
  </ul>
)

UserDropdown.defaultProps = {
  currentUser: {
    account_manager: false,
    email: '',
    name: ''
  }
}

UserDropdown.propTypes = {
  ariaHidden: PropTypes.bool,
  currentUser: PropTypes.shape({
    account_manager: PropTypes.bool,
    email: PropTypes.string,
    firm_user: PropTypes.bool,
    name: PropTypes.string,
    quarterback: PropTypes.bool
  }),
  toggleDropdown: PropTypes.func
}

export default UserDropdown

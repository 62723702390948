import DropdownRowBase from './DropdownRowBase'

import {MyQuarterbackDashboard} from '../../../../shared_components/Links'

import proSwitchIcon from '../../../assets/ep-switch.svg'

const QuarterbackDashboardRow = () => (
  <DropdownRowBase>
    <MyQuarterbackDashboard>
      <img
        alt='pro-switch-icon'
        className='dropdown-icon'
        src={proSwitchIcon}
      />
      Pro Account
    </MyQuarterbackDashboard>
  </DropdownRowBase>
)

export default QuarterbackDashboardRow
